/* eslint-disable */
<template>
  <div>
    <b-jumbotron
      bg-variant="dark"
      text-variant="light"
      :style="`background-image: url(${DashboardBackgroundImg}); background-size: cover; background-position: center`"
    >
      <div class="container">
        <b-row>
          <b-col lg="8" md="12">
            <h3 class="text-lg-h3">
              <strong>Welcome back,</strong> {{ userFullname }}
            </h3>
            <div class="mt-5" v-html="welcomeMsg"></div>
          </b-col>
        </b-row>
      </div>
    </b-jumbotron>

    <div class="container">
      <h2 class="my-4">My Courses</h2>
      <b-row v-if="DashboardEnrolledCourse.length > 0">
        <template v-for="(course, i) in DashboardEnrolledCourse">
          <b-col
            lg="4"
            md="12"
            sm="12"
            class="mb-6"
            :key="course.trainee_course_id"
          >
            <b-card
              class="bg-course position-relative"
              :style="`background-image: url(${backgroundImg[i]})`"
              text-variant="white"
              sub-title-text-variant="danger"
              :title="course.course_name"
              :sub-title="course.category"
            >
              <div
                v-if="course.completion_status_id !== 1"
                class="position-absolute top-0 right-0"
              >
                <b-img
                  class="p-1 pt-3"
                  src="/assets/img/badges/completed_badge.svg"
                  width="60px"
                ></b-img>
              </div>
              <b-card-text class="pb-5">
                {{ course.trainee_course_description }}
              </b-card-text>
              <b-card-text class="pb-5">
                {{ course.trainee_course_long_description }}
              </b-card-text>
              <b-button
                @click.prevent="viewCourseDetail(course.trainee_course_id)"
                variant="primary"
                >View course</b-button
              >
            </b-card>
          </b-col>
        </template>
      </b-row>
      <b-row v-if="DashboardEnrolledCourse.length < 1">
        <b-col>
          <div class="p-5 bg-light text-center border">
            <div style="height: 5rem"></div>
            <h5>Not Enrolled to any course yet.</h5>
            <div style="height: 5rem"></div>
          </div>
        </b-col>
      </b-row>
    </div>

    <div class="d-none">
      <pre>-->{{ backgroundImg[0] }}</pre>
      <pre>-->{{ DashboardEnrolledCourse }}</pre>
      <pre>-->{{ DashboardEnrolledCourseObj }}</pre>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";
import {
  GET_VIEW_COURSE_ENROLLED,
  GET_VIEW_TRAINEE_COURSE_COVER_PAGE,
} from "@/core/services/store/modules/trainee/course.module";
import UserService from "@/core/services/user.service";

export default {
  name: "trainee-profile",
  async mounted() {
    await this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$route.meta.title },
    ]);
    let _this = this;
    await this.$store
      .dispatch(GET_VIEW_COURSE_ENROLLED, this.defaultPayload)
      .then((res) => {
        if (res.response_code === 2100) {
          let data = res.data.traineeCoursesList;

          data.forEach(function (module) {
            _this.$store
              .dispatch(GET_VIEW_TRAINEE_COURSE_COVER_PAGE, {
                trainee_course_id: module.trainee_course_id,
              })
              .then((resp) => {
                if (resp.response_code === 2100) {
                  let link = resp.data.link;
                  _this.backgroundArray.push(link);
                }
              });
          });
        }
      });
  },
  methods: {
    getBackground(index) {
      console.log(index);
    },
    viewCourseDetail(traineeCourseId) {
      this.$router.push({
        name: "trainee-course",
        params: {
          traineeCourseId: traineeCourseId,
        },
      });
    },
  },
  data() {
    return {
      backgroundArray: [],
      welcomeMsg:
        "<h5>Ready to play your role in the organization?</h5> <p>On this page, you can access the courses that you have been enrolled by your organization administrator. Feel free to get in touch with us if there are any issue.</p>",
    };
  },
  computed: {
    processParam() {
      let list = this.$store.state.trainee_course.courseEnrolledList;

      // let x = list;
      // console.log("-->", x.List_of_Trainee_Module_Item);

      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
    username() {
      let userData = JSON.parse(UserService.getUserData());
      return userData.username;
    },
    userFullname() {
      let userData = JSON.parse(UserService.getUserData());
      return userData.fullname;
    },
    backgroundImg() {
      const list = this.backgroundArray;
      if (list) {
        let bg = [];
        list.forEach((course_bg) => {
          bg.push(course_bg);
        });
        return bg;
      } else {
        let bg = [];
        bg.push("/assets/img/placeholders/sample_bg01.jpg");
        return bg;
      }
    },
    DashboardBackgroundImg() {
      return "/assets/img/placeholders/dashboard_trainee_bg01.jpg";
    },
    DashboardEnrolledCourseObj() {
      return this.DashboardEnrolledCourse;
    },
    DashboardEnrolledCourse() {
      const list = this.$store.state.trainee_course.courseEnrolledList;

      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
  },
};
</script>
<style lang="scss">
.bg-course {
  position: relative;
  height: 100%;
  background-position: center;
  background-size: cover;
}
.card-body {
  &.card-img-overlay {
    .card-title,
    .card-subtitle,
    .card-text {
      text-shadow: 0px 0px 5px #000000;
    }
  }
}
</style>
